import * as React from 'react'
import { Link } from 'gatsby'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import Imprint from "../components/imprint/Imprint";
import HeaderDesktop from "../components/HeaderDesktop";
import Helmet from "react-helmet";

const PageTwo = ( {pathContext: { locale }}) => (
  <IndexLayout locale={locale}>
    <Helmet

      title={'Impressum'}
      meta={[
        { name: 'description', content: 'Vilhelm7 Berlin Residences. Wilhelmstr. 7 10963 Berlin' },
        { name: 'keywords', content: 'Vilhelm7, V7, Apartments, buchen, Hotel, Zimmer, Berlin, Übernachtung, Kreuzberg'},
        {name: 'page-type', content: 'Hotel, Apartments'},
        { name:'copyright', content:"https://www.vilhelm7.de"},
        { name:'X-UA-Compatible', content:"IE=edge"},
      ]}

    />
    <HeaderDesktop/>
    <Imprint></Imprint>
  </IndexLayout>
)

export default PageTwo
