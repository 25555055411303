import * as React from 'react'
import styled from '@emotion/styled'


const StyledDiv = styled.div`

  width: 40%;
text-align:center;
margin-left:auto;
margin-right:auto;
font-family: "Theano Didot", Regular;

padding-top:1%;
font-size: 1.2em;
color:#444;

 @media screen and (max-width: 1200px) {
   width:80%;
   }



`;



const Einzug = styled.p`
font-family: "Theano Didot", Regular;
line-height: 1.5em;
text-indent: 20px;
height:100%;
`;

const P = styled.p`
font-family: "Theano Didot", Regular;
line-height: 1.5em;

margin-top:1.1%;


`;

const PNoSpace = styled.p`
font-family: "Theano Didot", Regular;
line-height: 1.5em;
margin-bottom:0px !important;
margin-top:1.1%;


`;

const PLeft = styled.p`
font-family: "Theano Didot", Regular;
line-height: 1.5em;
text-align:left;
text-indent: 20px;
margin-top:1.1%;
letter-spacing: 1px;


`;

const PName = styled.p`
font-family: "Theano Didot", Regular;
line-height: 1.5em;
letter-spacing: 1px;
margin-bottom:0.1em;



`;

const PBold = styled.p`
font-family: "Theano Didot", Regular;
font-weight:bold;
line-height: 1.5em;

margin-bottom:0;
margin-top:3.5%;
letter-spacing: 2px;

`;

const PProduct = styled.p`

margin-top:2.5%;
letter-spacing: 1px;

`;

const Div = styled.div`
border-bottom: 2px solid rgba(68, 68, 68,.5);
margin-top:40px;
`

const StyledP = styled.p`
font-family: "Theano Didot", Regular;
font-style: italic;
font-size: 160%;
line-height: 45px;
letter-spacing: 2px;




`;




interface ImprintProps {
  className?: string
}

const Imprint: React.FC<ImprintProps> = ({ }) => (
  <StyledDiv>
    <br/><br/>

    <PBold>Betreibung Vilhelm7 Berlin Residences</PBold>

    <br/>
    <P>2inSeven GmbH i. L.<br/>
      <P>GF I. Alexander Kevin</P>
      Wilhelmstr. 7 - 10963 Berlin <br/>
      Amtsgericht Charlottenburg <br/>
      HRB: 166094 B <br/>
      USt.-IdNr.: DE300020271<br/>

    </P>

  <br/>
    <PBold>Management Vilhelm7 Berlin Residences</PBold>
    <br/>

    <P>7Arte Group UG (haftungsbeschränkt)<br/>
      <P> Geschäftsführung: Yvonne Kevin, I. Alexander Kevin</P>

      Wilhelmstr. 7 - 10963 Berlin <br/>
      Amtsgericht Charlottenburg <br/>
      HRB: 238250 B <br/></P>


    <P> <a href={'mailto:info@7artegroup.com'}>info@7artegroup.com</a> <br/>
      Telefon +49 162 6377634 <br/> </P>

    <br/>
<Div></Div>
    <br/><br/>
    <StyledP>Gesamtkonzept:</StyledP>
      <PName>Yvonne Kevin | 7Arte Group UG </PName>
      <br/>
    <StyledP>Partner & Lieferanten:</StyledP>
    <PBold>Art:</PBold>
    <PName>Jadore Tong </PName>
    <PName>   Volker Leyendecker </PName>
    <PName>  Christina Wachendorff </PName>
     <PName> Felix Broede </PName>

    <PBold>Beleuchtung:</PBold>
    <PName> Lichthaus Laubstein </PName>

    <PBold>Interior:</PBold>
    <PName> Christina Wachendorff </PName>
    <PName>  Josche Allwardt - Möbelbau </PName>
    <PName> Antikhandel Bonn </PName>

    <PBold>Fotografie:</PBold>
    <PName>  Felix Broede </PName>

    <PBold>Programmierung:</PBold>
    <PName>7Arte Group UG </PName>

    <br/> <br/>

    <StyledP>Unsere Vilhelm7 Produkte:</StyledP>
  <PBold/>
    <PProduct> AESOP, Australia  </PProduct>
    <PProduct>  Manufaktur Siebenberge, Königswinter </PProduct>
    <PProduct>  Apo's Käseparadies, Berlin </PProduct>
    <PProduct>  Bäckerei Heinevetter, Berlin  </PProduct>
    <PProduct>  Jean de Pierre, Berlin  </PProduct>
    <PProduct>   Brandstifter Gin, Berlin  </PProduct>
    <PProduct>   ManuTeeFaktur, Berlin </PProduct>


    <Div></Div>
<br/> <br/>
    <PBold>1. Haftungshinweis</PBold>
    <PLeft> Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links.
      Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
      Sämtliche Preisangaben auf unseren Webseiten sind ohne Gewähr.</PLeft>
    <br/>
    <PBold>2. Externe Links</PBold>
    <PLeft> Diese Website enthält Verknüpfungen zu Websites Dritter ("externe Links").
      Diese Websites unterliegen der Haftung der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen
      Verknüpfung der externen Links die fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen.
      Zu dem Zeitpunkt waren keine Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle
      und zukünftige Gestaltung und auf die Inhalte der verknüpften Seiten. Das Setzen von externen Links bedeutet nicht,
      dass sich der Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine ständige Kontrolle
      der externen Links ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar.
      Bei Kenntnis von Rechtsverstößen werden jedoch derartige externe Links unverzüglich gelöscht.</PLeft>
    <br/>
    <PBold>3. Urheber- und Leistungsschutzrechte</PBold>
    <PLeft> Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheber- und Leistungsschutzrecht.
      Jede vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlichen
      Zustimmung des Anbieters oder jeweiligen Rechteinhabers.
      Dies gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw.
      Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen.
      Inhalte und Rechte Dritter sind dabei als solche gekennzeichnet. Die unerlaubte Vervielfältigung oder
      Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet und strafbar.
      Lediglich die Herstellung von Kopien und Downloads für den persönlichen,
      privaten und nicht kommerziellen Gebrauch ist erlaubt.</PLeft>
    <br/>
    <PBold>
      4. Bestandsdaten</PBold>
    <PLeft> Sofern zwischen Ihnen und der 2inSeven GmbH i. L. ein Vertragsverhältnis begründet,
      inhaltlich ausgestaltet oder geändert wird, erheben und verwenden wir personenbezogene Daten von Ihnen,
      soweit dies zu diesen Zwecken erforderlich ist. Eine andere Verwendung oder Weitergabe an Dritte erfolgt nicht. <br/>
<br/>
      Im Rahmen entsprechender gesetzlicher Regelungen und auf Grund richterlicher Entscheidungen können
      wir verpflichtet werden, in begründeten Einzelfällen Auskünfte über die gespeicherten Daten an zuständige Behörden
      und Institutionen zu übermitteln, soweit dies zu Zwecken der Strafverfolgung, der Gefahrenabwehr
      oder zur Durchsetzung der Rechte am geistigen Eigentum erforderlich ist. </PLeft>
    <br/>
    <PBold> 5. Cookies </PBold>
    <PLeft>Um den Funktionsumfang unseres Internetangebotes zu erweitern und die Nutzung für Sie komfortabler zu gestalten,
      verwenden wir so genannte „Cookies“. Mit Hilfe dieser Cookies können bei dem Aufruf unserer Webseite Daten auf Ihrem
      Rechner gespeichert werden. Sie haben die Möglichkeit, das Abspeichern von Cookies auf Ihrem Rechner durch entsprechende
      Einstellungen in Ihrem Browser zu verhindern.
      Hierdurch könnte allerdings der Funktionsumfang unseres Angebotes eingeschränkt werden. </PLeft>
    <br/>
    <PBold> 6. Auskunftsrecht</PBold>
    <PLeft>Als Nutzer unseres Internetangebotes haben Sie das Recht, von uns Auskunft über die zu
      Ihrer Person/Ihrem Unternehmen oder zu Ihrem Pseudonym gespeicherten Daten zu verlangen.
      Auf Ihr Verlangen kann die Auskunft auch elektronisch erteilt werden.</PLeft>
  </StyledDiv>


)

export default Imprint


/*

    <PNoSpace> Yvonne Kevin</PNoSpace>
 */
